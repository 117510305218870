import React, { useEffect } from "react";
import {
    Card,
    CardContent,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../services/auth-services";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useState } from "react";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
    email: yup
        .string("Insira um email válido")
        .email("Email inválido")
        .required("Preenchimento obrigatório"),
    senha: yup.string().required("Preenchimento obrigatório"),
});

const Login = ({ disableSmLogin }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { setAuthToken } = useJumboAuth();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const onSignIn = async (email, password, setSubmitting) => {
        try {
            const data = await authServices.signIn({ email, password });
            console.log('onSignIn', data)
            setAuthToken(
                data?.access_token,
                data.username,
                data.email,
                data.id,
                data?.permissions,
                data?.avatarUrl
            );
            navigate("/");

            setSubmitting(false);
        } catch (err) {
            if (
                err.response &&
                err.response.data &&
                err.response.data.error &&
                err.response.data.message
            ) {
                enqueueSnackbar(err.response.data.message, {
                    variant: "error",
                    
                });
            } else if (err.response && err.response.data) {
                alert(err.response.data);
            } else {
                // Aqui você pode tratar outros erros ou exibir uma mensagem padrão
                console.log(err);
                enqueueSnackbar("Ocorreu um erro ao fazer login.", {
                    variant: "error",
                });
            }

            setSubmitting(false);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
   
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if(localStorage.getItem('id')) navigate("/")
    })

    return (
        <Div
            sx={{
                width: 750,
                maxWidth: "100%",
                margin: "auto",
                p: 4,
            }}
        >
            <Card
                sx={{
                    display: "flex",
                    minWidth: 0,
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <CardContent
                    sx={{
                        flex: "0 1 300px",
                        position: "relative",
                        /* background: `#0267a0 url(/images/logos/logo.png) no-repeat center`, */
                        backgroundImage:
                            "url(/images/logo_mae_coruja.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",

                        "&::after": {
                            display: "inline-block",
                            position: "absolute",
                            content: `''`,
                            inset: 0,
                            /* backgroundColor: alpha('#0267a0', .65) */
                        },
                    }}
                >
                </CardContent>
                <h1>Adicionado com pipeline dockerizada :D</h1>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            emailOrCpf: "",
                            senha: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.senha, setSubmitting);
                        }}
                    >
                        {({
                            isSubmitting,
                            handleChange,
                            values,
                            setFieldTouched,
                            setFieldValue,
                        }) => (
                            <Form
                                style={{ textAlign: "left" }}
                                noValidate
                                autoComplete="off"
                            >
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        sx={{ background: "#ffffff" }}
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        id="senha"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        name="senha"
                                        value={values.senha}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ background: "#ffffff" }}
                                        label="Senha"
                                    />
                                </Div>
                                {/* 
                                    <Div
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Link
                                            to="/esqueci-senha"
                                            style={{ color: "#fff" }}
                                        >
                                            Esqueceu a senha? Clique aqui
                                        </Link>
                                    </Div>
                                 */}
                                <LoadingButton
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >
                                    Acessar
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;

const exemple = {
  "funcionarios": [
    {
      "nome": "chico",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "jubesclaudo",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "gru",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "federico",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "clarencio",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "Dr. Nefário",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "martina",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    },
    {
      "nome": "toin",
      "horarios": [
        {
          "06:00": "Tarefa 1",
          "08:30": "Tarefa 2",
          "10:30": "Tarefa 3",
          "13:00": "Tarefa 4",
          "15:30": "Tarefa 5"
        }
      ]
    }
  ]
}


  export default exemple
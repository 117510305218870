import {
    Box,
    Button,
    Card,
    IconButton,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ApiService from 'app/services/config';

export const FilesItem = ({ doc, handleEditFile, modalAlert, handleOpen }) => {
    const { userId } = useParams();

    const handleDownload = async () => {
        try {
            const response = await ApiService.get(
                `${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${doc.nome}`,
                {
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.nome);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erro ao fazer download:', error);
        }
    };
    return (
        <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Prestador
                    </Typography>

                    <Typography mt={1}>{doc?.Prestador?.nome}</Typography>
                </Box>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Finalidade do arquivo
                    </Typography>

                    <Typography mt={1}>{doc?.tipo_de_arquivo}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Info Complementar
                    </Typography>

                    {/* <Typography mt={1}>{doc?.nome}</Typography> */}
                    {doc?.nome ? (
                        <Tooltip
                            title='Visualizar arquivo'
                            TransitionComponent={Zoom}
                        >
                            <a
                                href={`${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${doc.nome}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {doc?.nome}
                            </a>
                        </Tooltip>
                    ) : (
                        <Typography mt={1}> Não possui </Typography>
                    )}
                </Box>

                <Box display={'flex'} gap={1}>
                    <Tooltip title='Download' TransitionComponent={Zoom}>
                        <IconButton
                            aria-label='download'
                            color='success'
                            download={doc.nome}
                            target='_blank'
                            rel='noreferrer'
                            onClick={handleDownload}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar' TransitionComponent={Zoom}>
                        <IconButton
                            aria-label='editar'
                            color='info'
                            onClick={() => handleEditFile(doc)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Remover' TransitionComponent={Zoom}>
                        <IconButton
                            aria-label='remover'
                            color='error'
                            onClick={() => modalAlert(doc.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    );
};

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LayersIcon from '@mui/icons-material/Layers';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import MovieIcon from '@mui/icons-material/Movie';
import ExtensionIcon from '@mui/icons-material/Extension';
import FacebookIcon from "@mui/icons-material/Facebook";
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';

export const pinnedData = [
    {
        label: 'Categoria 1',
        items: [
            { id: 1, label: 'Item 1.1', color: 'primary' },
            { id: 2, label: 'Item 1.2', color: 'secondary' },
        ],
    },
    {
        label: 'Categoria 2',
        items: [
            { id: 3, label: 'Item 2.1', color: 'primary' },
            { id: 4, label: 'Item 2.2', color: 'secondary' },
        ],
    },
    
];
